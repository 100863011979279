<template>
  <div class="fu-lang">
    <span
      v-for="(item, index) in langs"
      :key="index"
      class="lang-item"
      :class="{active: currentLang == item.value}"
      @click="handleToggle(item)"
    >{{ item.label }}</span>
  </div>
</template>

<script>

import store from '../../../../store'
import { getTenantRegion } from '@fu/core'

export default {
  name: 'Lang',
  data() {
    return {
      currentLang: 'en'
    }
  },
  computed: {
    region() {
      return getTenantRegion()
    },
    langs() {
      var baseLangs = [{ label: 'EN', value: 'en' }]

      if (this.region === 'VN') {
        baseLangs = [...baseLangs, { label: 'VN', value: 'vi' }]
      } else if (this.region === 'ID') {
        baseLangs = [...baseLangs, { label: 'ID', value: 'id' }]
      }

      return baseLangs
    },
    langKey() {
      if (this.region === 'VN') {
        return 'vi'
      } else if (this.region === 'ID') {
        return 'id'
      } else {
        return 'en'
      }
    }
  },
  mounted() {
    this.handleToggle({ value: this.langKey })
  },
  methods: {
    handleToggle(lang) {
      console.log(lang)
      this.currentLang = lang.value
      this.$i18n.locale = lang.value

      store.dispatch('base/changeLang', lang.value)
      this.$emit('changeLang', lang.value)
    }
  }
}
</script>

<style lang="scss">
.fu-lang {
  position: absolute;
  top: 3%;
  right: 4%;
  display: flex;
  border: 1px solid #FFBFBF;
  overflow: hidden;
  z-index: 999;
  padding: 2px;
  background: #FBFBFB;
  border-radius: 5px;

  .lang-item {
    padding: 2px;
    width: 35px;
    font-size: 15px;
    text-align: center;
    color: #D90109 ;
    background: #FBFBFB;
    border-radius: 5px;

    &.active {
    color: #FBFBFB;
    background: #D90109;
    }
  }
}
</style>

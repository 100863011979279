<template>
  <div class="valid-time-countdown">
    <div v-if="title" class="tips">{{ title }}</div>
    <div
      v-else
      class="tips"
      :class="{'expired': expired}"
    >
      {{ expired ? $t('validTimeExpiredTips') : isID ? $t('paymentCountDownTipID') : $t('paymentCountDownTip') }}
    </div>

    <count-down
      v-if="!expired"
      :time="time"
      format="HH:mm:ss"
      class="count-down"
      @finish="finish"
      @change="change"
    >
      <template #default="timeData">
        <div v-if="!timeData.days && !timeData.hours && !timeData.minutes" class="count-down-in">
          <span class="block">{{ timeData.seconds >= 10 ? timeData.seconds: `0${timeData.seconds}` }}s</span>
        </div>
        <div v-else class="count-down-in">
          <div class="block">
            <span class="number">{{ timeData.days }}</span>
            <span>{{ $t('days') }}</span>
          </div>
          <span class="colon">:</span>
          <div class="block">
            <span class="number">{{ timeData.hours >= 10 ? timeData.hours: `0${timeData.hours}` }}</span>
            <span>{{ $t('hours') }}</span>
          </div>
          <span class="colon">:</span>
          <div class="block">
            <span class="number">{{ timeData.minutes >= 10 ? timeData.minutes: `0${timeData.minutes}` }}</span>
            <span>{{ $t('minutes') }}</span>
          </div>
          <span class="colon">:</span>
          <div class="block">
            <span class="number">{{ timeData.seconds >= 10 ? timeData.seconds: `0${timeData.seconds}` }}</span>
            <span>{{ $t('seconds') }}</span>
          </div>
        </div>
      </template>
    </count-down>
  </div>
</template>

<script>
import { CountDown } from 'vant'
export default {
  name: 'validTimeCountdown',
  components: {
    CountDown
  },
  props: {
    title: { type: String, default: '' },
    expiryDate: {
      type: [String, Number, Date],
      default: new Date().getTime()
    },
    expired: { type: Boolean, default: false },
    isID: { type: Boolean, default: false }
  },
  data() {
    return {
    }
  },
  computed: {
    timeZone() {
      return this.$store.state.base.timeZone
    },
    time() {
      return this.expiryDate - new Date().getTime()
    }
  },
  methods: {
    finish() {
      this.$router.push(`/inspection/linkExpired`)
    },
    change(time) {
      // console.log(time)
    }
  }
}
</script>

<style lang="scss" scoped>
.valid-time-countdown {
  margin-bottom: 0px;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .tips{
    margin-top: 10px;
    width: 100%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #4B4B4B;
    text-align: center;

    &.expired {
      color: #C82A21;
    }
  }
}
.count-down{
  margin-top: 15px;
  display: inline-block;
  position: relative;
  overflow: hidden;

  &::after{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: -80px;
    left: 35px;
    z-index: 1;
  }
.count-down-in{
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
}
.colon {
  display: inline-block;
  margin: 0 4px;
  font-size: 17px;
  font-weight: 900;
  color: #000;
  line-height: 50px;
}
.block {
  margin: 0 10px;
  display: flex;
  color: #000;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .number {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 18px;
    height: 50px;
    width: 50px;
    background-color: #f2f2f2;
    text-align: center;
    border-radius: 10px;
    line-height: 3;
  }
  span {
    font-size: 16px;
    font-weight: lighter;
    line-height: 100%;
    color: #4B4B4B;
  }
}

</style>
